<template>
  <section>
    <div class="tabmenudemo-content">
      <br>
      <selectCalendario @exibirTabela="exibirTabela" :mes_nome="this.$route.query.title" :mes="this.$route.query.label" :tabelaDias="tabelaDias" mesNumero="02"
         :diaSelecionado="this.infoParaEnviar.dia" />
    </div>

    <div class="row">
      <h2 style="font-size: 17px;font-weight: 400;"  v-if="infoParaEnviar.dataSelecionada">Data selecionada: {{infoParaEnviar.dataSelecionada}}</h2>

      <div v-if="infoParaEnviar.dataSelecionada">
        <h2 style="font-size: 16px;font-weight: 400;"
          v-if="(this.segmento_tipo == 1 || this.serie_tipo == 1) && etapaDeEnvioDePresenca == 0">
           Realizar envio de presença do dia para a disciplina de {{this.$route.query.disciplina_nome}}
        </h2>

        <h2 style="font-size: 16px;font-weight: 400;"
          v-if="(this.segmento_tipo != 1 && this.serie_tipo == 2 && etapaDeEnvioDePresenca == 1)
           || (this.segmento_tipo == 2 && this.serie_tipo == 0 && etapaDeEnvioDePresenca == 1)">
           Realizar 1º envio de presença do dia para a disciplina de {{this.$route.query.disciplina_nome}}
        </h2>

        <h2 style="font-size: 16px;font-weight: 400;"
         v-if="(this.segmento_tipo != 1 && this.serie_tipo == 2 && etapaDeEnvioDePresenca == 2)
          || (this.segmento_tipo == 2 && this.serie_tipo == 0 && etapaDeEnvioDePresenca == 2)">
           Realizar 2º envio de presença do dia para a disciplina de {{this.$route.query.disciplina_nome}}
        </h2>

        <div v-if="((this.segmento_tipo == 1 || this.serie_tipo == 1) && etapaDeEnvioDePresenca == 1) || etapaDeEnvioDePresenca == 3">
          <h2 style="font-size: 16px;font-weight: 400;color:#e72020;">
            Todas presenças do dia foram enviadas para a disciplina de {{this.$route.query.disciplina_nome}} na data selecionada!
          </h2>
          <button class="btn btn-warning" @click="infoEditar()" v-if="!editarOk">Editar presença para data selecionada</button>
        </div>
      </div>
    </div>

    <div class="row" v-if="selectable && infoParaEnviar.dia">
      <h4>Alunos da Turma  </h4>
      <table-prof-presenca01 @marcarPresenca="marcarPresenca" :alunos="alunos" :st_segmento="this.$route.query.segmento_id"
       :etapaDeEnvioDePresenca="etapaDeEnvioDePresenca" />
    </div>

    <div class='col-md-12' v-if="this.selectable &&  infoParaEnviar.dia" style="left:0px;width:133px;">
      <va-inner-loading class="flex-center py-3" style="width: 100%;" :loading="isLoading">
          <button class="btn btn-primary" @click="presenca()">Enviar Presença</button>
      </va-inner-loading>
    </div>

    <div v-if="editarOk && (this.segmento_tipo == 1 || this.serie_tipo == 1)">
      <editarFundamental1 @marcarPresenca="marcarPresenca" :alunos="alunos" @EditarPresencaDaTurma="EditarPresencaDaTurma" @fecharEditarFundamentalUm="fecharEditarFundamentalUm"/>
    </div>

    <div v-if="editarOk && (this.segmento_tipo == 2 || this.serie_tipo == 2)">
      <editarFundamental2 @marcarPresencaFundamentalDois="marcarPresencaFundamentalDois" :alunos="alunos" @EditarPresencaDaTurma="EditarPresencaDaTurma" @fecharEditarFundamentalUm="fecharEditarFundamentalUm"/>
    </div>

  </section>
</template>

<script>
import selectCalendario from '@/components/presenca/selectCalendario.vue'
import tableProfPresenca01 from '@/components/presenca/tableProfPresenca01.vue'
import tableProfPresenca02 from '@/components/presenca/tableProfPresenca02.vue'
import editarFundamental1 from '@/components/presenca/editarFundamental1.vue'
import editarFundamental2 from '@/components/presenca/editarFundamental2.vue'

import { Calendario } from "@/class/calendario";
import { Presenca } from "@/class/presenca";
import { Turma } from "@/class/turma";
import { Servidores } from "@/class/servidores";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";

export default {
props: {
},
components: {
  selectCalendario,
  tableProfPresenca01,
  tableProfPresenca02,
  editarFundamental1,
  editarFundamental2,
},
data () {
  return {
    mesLoading:false,
      isLoadingPageNotaTotal:false,
      isLoadingPage:false,
      desabilitarParaImprimirPdf:false,
      presencasDoAluno:[],
      editarOk:0,
      porcentagemParaSerAprovado:70,
      meses : ['fevereiro', 'marco', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
      ejaEhFundamental1:2,
      apenasVisualizar:false,
      infoParaEnviar: {
        disciplina_id:'',
        mes:'',
        turma_id:'',
        segmento_id:'',
        ano:'',
        semana:'',
        dataSelecionada:'',
      },
      anoDeHoje:'',
      diaSelecionado:'',
      mesSelecionado:'',
      dataDeHoje:'',
      st_id: null,
      st_turma_id: null,
      st_professor_id: null,
      st_turma_disciplina_id:null,
      st_segmento:null,
      st_dataSelecionada:null,
      st_escola_id:null,
      st_serie_id:null,
      st_turno:null,
      turma_dados:[],
      isLoading:false,
      disciplinaSelecionadaPeloProf: null,
      alunos:[],
      alunosPresente: [],
      perPage: 5,
      currentPage: 1,
      selectable:true,
      tipo: 1,
      infoVerificarPresenca: {
          turma_id: this.st_turma_id,
          professor_id: this.st_professor_id,
          turma_disciplina_id: null,
          dia:null,
          mes:null,
          ano:null,
          disciplina_id:null,
          mes_numeral:0,
      },
      verificadorDePresenca:[],
      etapaDeEnvioDePresenca:0,
      disciplina:[],
      tabelaDias:[],
      tabelaDiasApenasAnosLetivoColunas:[],
      tabelaDiasApenasAnosLetivoInfo:[],
      presenca_total:[],
      segmento_tipo: 1,
      serie_tipo: 0,
  }
},
methods: {
    async test(mes, mesExibir) {
        this.mesLoading = true;
        this.mesSelecionado = mesExibir;
        this.tabelaDias = [];
        this.infoParaEnviar.dia = '';
        this.infoParaEnviar.semana = '';
        this.infoParaEnviar.dataSelecionada = '';
        this.infoParaEnviar.ano = this.$route.query.ano;


        let novo = {
          disciplina_id: this.$route.query.disciplina_id,
          mes: mes,
          turma_id: this.$route.query.turma_id,
          segmento_id: this.$route.query.segmento_id,
          serie: this.$route.query.serie_id,
          ano: this.$route.query.ano,
        }

        let data = await Presenca.presencaMontarCalendarioProfessor(novo);
        this.tabelaDias = data.data;
        this.tabelaDiasApenasAnosLetivoColunas = [];

        //Apenas com dia letivo
        for (var i = 0; i < this.tabelaDias.length; i++) {
          if (this.tabelaDias[i].letivo == '0')
            continue;
          if (this.tabelaDias[i].dia.length == 1)
            this.tabelaDias[i].dia = '0' + this.tabelaDias[i].dia;
            this.tabelaDiasApenasAnosLetivoColunas.push(this.tabelaDias[i]);
        }

        this.tabelaDiasApenasAnosLetivoInfo = [];
        for (var i = 0; i < this.alunos.length; i++) {
          let novo = {
            nome: this.alunos[i].nome,
            presencasDias: []
          }
          for (var j = 0; j < this.tabelaDiasApenasAnosLetivoColunas.length; j++) {
              if (this.tabelaDiasApenasAnosLetivoColunas[j].presenca[i] != undefined)
                novo.presencasDias.push(this.tabelaDiasApenasAnosLetivoColunas[j].presenca[i]);
              else
                novo.presencasDias.push('');
          }
          this.tabelaDiasApenasAnosLetivoInfo.push(novo);
        }
        this.mesLoading = false;
        return true;
    },
    async exibirTabela(dia, semana, mes, mesLetra) {

      this.editarOk = false;
      this.infoParaEnviar.dia = dia;
      this.infoParaEnviar.mes = mesLetra;
      this.infoParaEnviar.semana = semana;
      if (dia.length == 1)
        dia = '0' + dia;
      this.infoParaEnviar.dataSelecionada = dia+'/'+mes+'/'+this.$route.query.ano;
      this.verificarPresencaTurma();

    },
    async verificarPresencaTurma() {
      this.infoVerificarPresenca.dia = this.infoParaEnviar.dia;
      this.infoVerificarPresenca.mes = this.infoParaEnviar.mes;
      this.infoVerificarPresenca.ano = this.$route.query.ano;
      this.infoVerificarPresenca.turma_id = this.$route.query.turma_id;
      this.infoVerificarPresenca.turma_disciplina_id = this.$route.query.turma_disciplina_id;

      let pf = await Servidores.buscarServidorIdLogin(this.st_professor_id);
      this.infoVerificarPresenca.professor_id = pf.data.id;

      let data1 = await Presenca.verificarPresencaDoDia(this.infoVerificarPresenca, pf.data.id);

      this.verificadorDePresenca = data1.data;

      if (this.verificadorDePresenca.possui && (this.segmento_tipo == 1 || this.serie_tipo == 1)) {
        this.selectable = false;
        this.etapaDeEnvioDePresenca = 1;
      }

      else if (this.segmento_tipo == 1 || this.serie_tipo == 1) {
        this.etapaDeEnvioDePresenca = 0;
        this.selectable = true;
      }
      else {
        if (!this.verificadorDePresenca.possui) { // Turma que nao eh fundamental 1 nao enviou a primeira presenca
          this.etapaDeEnvioDePresenca = 1;
          this.selectable = true;
        }
        else if (this.verificadorDePresenca.possui == 1) { // Turma que nao eh fundamental 1  enviou a primeira presenca, mas nao enviou a segunda
          this.etapaDeEnvioDePresenca = 2;
          this.selectable = true;
        }
        else { // Turma que nao eh fundamental 1 enviou todas as presencas
          this.etapaDeEnvioDePresenca = 3;
          this.selectable = false;
        }
      }
      this.isLoading = false;
    },

    marcarPresenca(n,item){
      item.presente = n;
    },
    marcarPresencaFundamentalDois(chamada, n, item){
      if (chamada == 1)
        item.presenca_1 = n;
      else
        item.presenca_2 = n;
    },

    async turmaAlunos() {
      this.alunos = [];
      let data = await Turma.obtemAlunosTurma(this.$route.query.turma_id);
      this.alunos = data.data;
    },

    async presenca(){
      try {
        this.alunosPresente = [];
        for (var i = 0; i < this.alunos.length; i++) {
          if (this.alunos[i].presente == undefined) {
            alert("Adicione as informações de presença para todos os alunos!");
            return false;
          }
          let novo = {
            aluno_id: this.alunos[i].aluno_id,
            id:  this.alunos[i].id,
            nome:  this.alunos[i].nome,
            turma_id:  this.alunos[i].turma_id,
            presente:  this.alunos[i].presente,
            tipo: (this.etapaDeEnvioDePresenca == 0 || this.etapaDeEnvioDePresenca == 1) ? 1 : 2,
            repetir_dia: this.st_segmento != 1,
            ano: this.infoParaEnviar.ano,
            mes: this.infoParaEnviar.mes,
            dia: this.infoParaEnviar.dia
          }
          this.alunosPresente.push(novo);
        }

        //Gerar presencas

        this.isLoading = true;
        let pf = await Servidores.buscarServidorIdLogin(this.st_professor_id);
        let data = await Presenca.criarPresencaDosAlunos(this.alunosPresente, pf.data.id, this.$route.query.turma_disciplina_id, this.$route.query.turma_id);
        if (this.etapaDeEnvioDePresenca == 0) {
          this.$vaToast.init({
            message: "Envio de presença do dia realizado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'botton-left',
            color:'success',
            duration: 3000,
            fullWidth: false,
          });
        }
        else if (this.etapaDeEnvioDePresenca == 1) {
          this.$vaToast.init({
            message: "Envio da primeira presença do dia realizado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'botton-left',
            color:'success',
            duration: 3000,
            fullWidth: false,
          });
        }
        else {
          this.$vaToast.init({
            message: "Envio da segunda presença do dia realizado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'botton-left',
            color:'success',
            duration: 3000,
            fullWidth: false,
          });
        }
        this.verificarPresencaTurma();
        this.turmaAlunos();
        this.isLoading = false;

      }catch(e) {
         this.$vaToast.init({
          message: "Erro ao enviar presença!",
          iconClass: 'fa-star-o',
          position: 'botton-left',
          color:'danger',
          duration: 3000,
          fullWidth: false,
        });
        this.isLoading = false;
      }

    },
    async infoEditar() {
      let novo = {
        escola_id: this.st_escola_id,
        turma_id: this.$route.query.turma_id,
        professor_id: this.st_professor_id,
        disciplina_id: this.$route.query.disciplina_id,
      }
      let data = await Presenca.buscarTurmaPresencaSemId2(novo);
      let novo1 = {
        turma_id: this.$route.query.turma_id,
        turma_presenca_id: data.data.id,
        dia: this.infoParaEnviar.dia,
        mes: this.infoParaEnviar.mes,
        ano: this.anoDeHoje,
      }
      let data1 = await Presenca.buscarPresencaDoDiaParaAluno2(novo1);

      if (this.segmento_tipo == 1 || this.serie_tipo == 1) {
        for (var i = 0; i < this.alunos.length; i++) {
          this.alunos[i].presente = data1.data[i].presenca_1;
        }
      }
      else if (this.segmento_tipo == 2 || this.serie_tipo == 2) {
        for (var i = 0; i < this.alunos.length; i++) {
          this.alunos[i].presenca_1 = data1.data[i].presenca_1;
          this.alunos[i].presenca_2 = data1.data[i].presenca_2;
        }
      }
      this.editarOk = 1;
    },
    fecharEditarFundamentalUm() {
      this.editarOk = !this.editarOk;
    },
    async EditarPresencaDaTurma(alunos) {
      try {
        this.alunosPresente = [];
        for (var i = 0; i < alunos.length; i++) {
          if (alunos[i].presente != undefined) {
            let novo = {
              aluno_id: alunos[i].aluno_id,
              id: alunos[i].id,
              nome:  alunos[i].nome,
              turma_id: alunos[i].turma_id,
              presenca_1: alunos[i].presente,
              presenca_2: null,
              ano: this.infoParaEnviar.ano,
              mes: this.infoParaEnviar.mes,
              dia: this.infoParaEnviar.dia
            }
            this.alunosPresente.push(novo);
          }
          else {
            let novo = {
              aluno_id: alunos[i].aluno_id,
              id: alunos[i].id,
              nome:  alunos[i].nome,
              turma_id: alunos[i].turma_id,
              presenca_1: alunos[i].presenca_1,
              presenca_2: alunos[i].presenca_2,
              ano: this.infoParaEnviar.ano,
              mes: this.infoParaEnviar.mes,
              dia: this.infoParaEnviar.dia
            }
            this.alunosPresente.push(novo);
          }
        }

        //Gerar presencas

        let pf = await Servidores.buscarServidorIdLogin(this.st_professor_id);
        let data = await Presenca.EditarPresencaDaTurma(this.alunosPresente, pf.data.id,
         this.$route.query.turma_disciplina_id, this.$route.query.turma_id);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'botton-left',
          color:'success',
          duration: 3000,
          fullWidth: false,
        });
        this.editarOk = false;
      }
      catch(e) {
        this.$vaToast.init({
          message: "Problema ao editar!",
          iconClass: 'fa-star-o',
          position: 'botton-left',
          color:'danger',
          duration: 3000,
          fullWidth: false,
        });
      }
    },

    async segmentoObtemUm(id){
       let data = await SegmentoEscolar.obtemUm(id);
       this.segmento_tipo = data.data.tipo;
    },

    async serieObtemUm(id){
      let data = await SeriesEscolar.obtemUm(id);
      if(this.segmento_tipo != 2){
        this.serie_tipo = data.data.tipo;
      }else{
        this.serie_tipo = 0;
      }
    },

    async init(){
      try{
        let calendario = await Calendario.calendarioAtual();
        //if (this.st_segmento == 1 || this.st_segmento == 3 || (this.st_segmento == 4 && this.ejaEhFundamental1)) {
        if (this.segmento_tipo == 1 || (this.segmento_tipo == 3 && this.serie_tipo == 1)) {
          this.porcentagemParaSerAprovado = calendario.data.porcent_fund1_falta;
        }
        else {
          this.porcentagemParaSerAprovado = calendario.data.porcent_outros_falta;
        }
        this.anoDeHoje = calendario.data.ano;
      }catch(e){

      }
    },
},
async beforeMount() {
  await this.init();

  this.st_professor_id = sessionStorage.getItem("Professor_professor_id");
  this.st_escola_id = sessionStorage.getItem("Professor_escola_id");
  await this.test(this.$route.query.label, this.$route.query.title);

  await this.segmentoObtemUm(this.$route.query.segmento_id);
  await this.serieObtemUm(this.$route.query.serie_id);
  await this.turmaAlunos();

},

}
</script>
